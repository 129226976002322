import React, { FC, ReactNode, useMemo } from 'react'
import Icon from 'components/atoms/Icon'
import Button from 'components/molecules/Button'
import { SearchInputController } from 'components/molecules/SearchInput'
import styled from 'styled-components'
import Paragraph from 'components/atoms/Paragraph'
import Headline from 'components/atoms/Headline'
import { useTranslation } from 'react-i18next'
import Filter from 'components/molecules/Filter'
import { useUserContext } from 'contexts/UserContext'
import ReactSelect from 'components/molecules/ReactSelect'
import { Realm } from '@types'
import { useQueryClient } from 'react-query'
import { isInternalDomain } from 'utils/location'

interface Props {
  title: string
  subtitle?: string
  search?: string
  filter?: React.ReactNode
  isFilterActive?: boolean
  onChangeSearch?: (query: string) => void
  onClearFilters?: () => void
  onExportCsv?: () => void
  onImportCsv?: () => void
  actions?: ReactNode
}

const PageHeader: FC<Props> = ({
  title,
  subtitle,
  search,
  filter,
  isFilterActive,
  onChangeSearch,
  onClearFilters,
  onExportCsv,
  onImportCsv,
  actions,
}) => {
  const [t] = useTranslation()
  const { state, dispatch: userDispatch, setSavedRealm } = useUserContext()
  const realms = useQueryClient().getQueryData('realms') as Realm[]

  const realmsOptions = useMemo(
    () => (realms || []).map((realm) => ({ value: realm.i_realm, label: realm.name })),
    [realms]
  )

  const isMultiRealmAdmin = realmsOptions.length > 1

  return (
    <>
      <Header>
        <Title>
          <Headline size="H3" text={title} />
          <Gap />
          {subtitle && <Subtitle size="12" text={subtitle} />}
        </Title>

        <SearchBar>
          {isInternalDomain() && isMultiRealmAdmin && (
            <ReactSelect
              value={{ value: state.realm.i_realm, label: state.realm.name }}
              onChange={({ value }) => {
                const payload = realms.filter((i) => i.i_realm === value)[0]
                userDispatch({ type: 'SET_REALM', payload })
                setSavedRealm(payload)
              }}
              options={realmsOptions}
              placeholder={t('placeholder.chooseRealm')}
              isShrink
            />
          )}
          {filter && <Filter filter={filter} isFilterActive={isFilterActive} onClearFilters={onClearFilters} />}
          {onExportCsv && (
            <Button icon={<Icon icon="uil:export" />} appearance="flat" onClick={() => onExportCsv()}>
              {t('common.exportCsv')}
            </Button>
          )}
          {onImportCsv && (
            <Button icon={<Icon icon="uil:import" />} appearance="flat" onClick={() => onImportCsv()}>
              {t('common.importCsv')}
            </Button>
          )}
          {onChangeSearch && <SearchInputController onChange={onChangeSearch} value={search!} />}
        </SearchBar>
      </Header>
      <ActionBar>{actions}</ActionBar>
    </>
  )
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  min-height: 40px;
`
const Title = styled.div`
  display: flex;
  align-items: center;
`

const Gap = styled.div`
  width: 10px;
`

const Subtitle = styled(Paragraph)`
  margin-top: 2px;
  color: ${(p) => p.theme.colors.coolGrey2};
`

const SearchBar = styled.div`
  display: flex;
  //grid: auto / auto auto auto;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
`
const ActionBar = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
  justify-content: end;
`

export { PageHeader }
